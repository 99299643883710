import React from 'react';

function Logo() {
  return (
    <div className="logo">
      <img src="/logo.png" alt="Logo" />
    </div>
  );
}

export default Logo;
