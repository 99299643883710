// CountdownTimer.js
import React, { useEffect, useState } from 'react';
import './CountdownTimer.css';

function CountdownTimer() {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2025-03-23T00:00:00');
    const now = new Date();
    const difference = targetDate - now;

    // Return all zeros if countdown has expired
    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }

    // Calculate time units if countdown is still active
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown">
      <div className="time-box">
        <span>{String(timeLeft.days).padStart(2, '0')}</span>
        <label>DAYS</label>
      </div>
      <span className="colon">:</span>
      <div className="time-box">
        <span>{String(timeLeft.hours).padStart(2, '0')}</span>
        <label>HOURS</label>
      </div>
      <span className="colon">:</span>
      <div className="time-box">
        <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
        <label>MINUTES</label>
      </div>
      <span className="colon">:</span>
      <div className="time-box">
        <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
        <label>SECONDS</label>
      </div>
    </div>
  );
}

export default CountdownTimer;
