import React from 'react';
import './SocialLinks.css';  // Create this file for styling

function SocialLinks() {
  return (
    <div className="social-links">

    <a href="https://www.instagram.com/ticketlush/" target="_blank" rel="noreferrer">
        <i className="fab fa-instagram"></i> {/* Instagram Icon */}
      </a>
      
      <a href="https://www.tiktok.com/@ticketlush" target="_blank" rel="noreferrer">
        <i className="fab fa-tiktok"></i> {/* TikTok Icon */}
      </a>

      <a href="https://x.com/ticketlush" target="_blank" rel="noreferrer">
        <i className="fab fa-twitter"></i> {/* X Icon */}
      </a>
      
      <a href="https://www.facebook.com/share/15w6uNDDtW/" target="_blank" rel="noreferrer">
        <i className="fab fa-facebook"></i>
      </a>

      <a href="https://www.youtube.com/@ticketlush" target="_blank" rel="noreferrer">
        <i className="fab fa-youtube"></i> {/* YouTube Icon */}
      </a>


    </div>
  );
}

export default SocialLinks;
