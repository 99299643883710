import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import CountdownTimer from './CountdownTimer';
import SocialLinks from './SocialLinks';
import Logo from './Logo';

function ComingSoonPage() {
  return (
    <div className="coming-soon-page">
      <header className="header">
        <div className="construction-tag">Our website is under construction</div>
        <h1 className="coming-soon">Coming Soon</h1>
        <p className="description">This page is coming soon. Stay tuned!</p>
      </header>
    </div>
  );
}

function HomePage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('EMAIL', email);

    fetch('https://aviorcompany.us17.list-manage.com/subscribe/post?u=458d32362d388599c83e49fc7&id=f317154628', {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then(() => {
        setMessage('Thank you for subscribing!');
      })
      .catch(() => {
        setMessage('Oops! Something went wrong. Please try again.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="content-container">
      <div className="text-section">
        <header className="header">
          <div className="construction-tag">Our website is under construction</div>
          <h1 className="coming-soon">We are coming soon</h1>
          <p className="description">
            Our team is putting in the effort to create a great experience, and we can't wait to share it with you. Stay tuned – the wait will be worth it!
          </p>
        </header>

        <form className="email-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter email address"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Notify Me'}
          </button>
        </form>

        {message && <p className="message">{message}</p>}
      </div>

      <div className="countdown-section">
        <CountdownTimer />
      </div>
    </div>
  );
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when switching to desktop
  const closeMenuOnDesktop = () => {
    if (window.innerWidth >= 768) {
      setIsMenuOpen(false);
    }
  };

  window.addEventListener('resize', closeMenuOnDesktop);

  return (
    <Router>
      <div className={`app ${isMenuOpen ? 'body-shift' : ''}`}>
        <div className="top-bar">
          <Logo />
          <div className="auth-links">
            <NavLink to="/login" className={({ isActive }) => isActive ? 'active' : ''} className="auth-link">Login</NavLink>
            <NavLink to="/signup" className={({ isActive }) => isActive ? 'active' : ''} className="auth-link signup-link">Signup</NavLink>
          </div>
        </div>

        {/* Hamburger menu visible only on mobile */}
        <div className="hamburger-menu" onClick={toggleMenu}>
          &#9776;
        </div>

        {/* Side panel - visible only on mobile when hamburger is clicked */}
        <div className={`side-panel ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>Home</NavLink></li>
            <li><NavLink to="/all-events" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>All Events</NavLink></li>
            <li><NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>About</NavLink></li>
            <li><NavLink to="/pricing" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>Pricing</NavLink></li>
            <li><NavLink to="/help-center" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>Help Center</NavLink></li>
            <li><NavLink to="/blog" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>Blog</NavLink></li>
            <li><NavLink to="/business-hub" className={({ isActive }) => isActive ? 'active' : ''} onClick={toggleMenu}>Business Hub</NavLink></li>
          </ul>
        </div>

        {/* Regular navbar visible on desktop */}
        <nav className="navbar">
          <ul>
            <li><NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink></li>
            <li><NavLink to="/all-events" className={({ isActive }) => isActive ? 'active' : ''}>All Events</NavLink></li>
            <li><NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink></li>
            <li><NavLink to="/pricing" className={({ isActive }) => isActive ? 'active' : ''}>Pricing</NavLink></li>
            <li><NavLink to="/help-center" className={({ isActive }) => isActive ? 'active' : ''}>Help Center</NavLink></li>
            <li><NavLink to="/blog" className={({ isActive }) => isActive ? 'active' : ''}>Blog</NavLink></li>
            <li><NavLink to="/business-hub" className={({ isActive }) => isActive ? 'active' : ''}>Business Hub</NavLink></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all-events" element={<ComingSoonPage />} />
          <Route path="/about" element={<ComingSoonPage />} />
          <Route path="/pricing" element={<ComingSoonPage />} />
          <Route path="/help-center" element={<ComingSoonPage />} />
          <Route path="/blog" element={<ComingSoonPage />} />
          <Route path="/business-hub" element={<ComingSoonPage />} />
          <Route path="/login" element={<ComingSoonPage />} />
          <Route path="/signup" element={<ComingSoonPage />} />
        </Routes>

        <div className="bottom-social">
          <SocialLinks />
          <footer>
            <p>&copy; 2024 by Ticket Lush. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </Router>
  );
}


export default App;
